import { FC } from 'react';
import clsx from 'clsx';

interface Props {
  className?: string;
}

const Badge: FC<Props> = ({ children, className }) => {
  const cls = clsx(
    'flex items-center justify-center rounded-full w-[30px] h-[30px] bg-brand-gray-1 text-brand-white text-lg font-semibold',
    className,
  );
  return <span className={cls}>{children}</span>;
};

export { Badge };
