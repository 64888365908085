import { VFC } from 'react';
import Image from 'next/image';
import OneTalkTime from '../../../public/images/home/1_TalkTime.png';
import OneReserve from '../../../public/images/home/1_Reserve.png';
import { Badge } from './Badge';
import OneArrow from '../../../public/images/home/1_Arrow.svg';

const SectionOne: VFC = () => {
  return (
    <section>
      <div className="flex flex-nowrap mb-10">
        <Image alt="Selection of a TalkTime event" src={OneTalkTime} />
        <Image alt="Selection of a kliggit Reserve event" src={OneReserve} />
      </div>
      <div className="relative mb-36">
        <Badge className="mb-3">1</Badge>
        <h3 className="w-2/3 font-semibold lg:text-xl">
          Pick the type of event you want to create
        </h3>
        <div className="absolute right-0 -top-4">
          <Image alt="" src={OneArrow} />
        </div>
      </div>
    </section>
  );
};

export { SectionOne };
