import Image from 'next/image';
import FourArrow from '../../../public/images/home/4_Arrow.png';
import FourEvent from '../../../public/images/home/4_Event.png';
import { VFC } from 'react';
import { Badge } from './Badge';

const SectionFour: VFC = () => {
  return (
    <section>
      <div className="flex">
        <div className="flex flex-col items-end">
          <div className="mt-8 w-2/3">
            <Badge className="mb-3">4</Badge>
          </div>
          <h3 className="w-2/3 font-semibold lg:text-xl">
            Engage in your video experience.
          </h3>
        </div>
        <div>
          <Image alt="" src={FourEvent} />
        </div>
      </div>
      <div className="ml-24 sm:ml-36 md:ml-48 lg:ml-64">
        <Image alt="" src={FourArrow} />
      </div>
    </section>
  );
};

export { SectionFour };
