import Image from 'next/image';
import { VFC } from 'react';
import PhoneOne from '../../../public/images/home/Footer_PhoneOne.png';
import PhoneTwo from '../../../public/images/home/Footer_PhoneTwo.png';

const FooterCTA: VFC = () => {
  return (
    <section className="relative bg-gradient-to-r from-[#4C6BDA] to-[#4159AC] w-screen px-7 sm:px-16 lg:px-24 xl:px-32 pt-[5.5rem] sm:pt-12 pb-16 min-h-[470px] sm:min-h-[316px] overflow-hidden mt-32">
      <div className="w-1/2">
        <p className="mb-12 text-base font-semibold leading-normal lg:text-xl">
          We see your value...
          <br />
          Now show it to the world!
        </p>
        <p className="mb-4 text-xs leading-normal lg:text-base">
          At kliggit, we believe everyone’s time is valuable. We are a platform
          that empowers everyone from large influence to small business,
          celebrity to tutor, TikToker to athlete, nutritionist to comedian...
        </p>
        <p className="text-xs leading-normal lg:text-base">
          to create events, engage in video interactions, and grow their
          following. Market yourself on the new social by booking others and
          listing your time today!
        </p>
      </div>
      <div>
        <div className="absolute top-0 right-0 sm:right-32 lg:right-64">
          <Image alt="" src={PhoneOne} quality={100} />
        </div>
        <div className="absolute right-0 -bottom-2 lg:right-32">
          <Image alt="" src={PhoneTwo} quality={100} />
        </div>
      </div>
    </section>
  );
};

export { FooterCTA };
