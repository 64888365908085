import { VFC } from 'react';
import Image from 'next/image';
import ThreeEventDetail from '../../../public/images/home/3_EventDetail.png';
import { Badge } from './Badge';
import ThreeArrow from '../../../public/images/home/3_Arrow.svg';

const SectionThree: VFC = () => {
  return (
    <section>
      <div className="flex">
        <Image alt="" src={ThreeEventDetail} />
        <div className="mt-24">
          <Badge className="mb-3">3</Badge>
          <h3 className="font-semibold lg:text-xl">
            Get ready for others to book your time!
          </h3>
        </div>
      </div>
      <div className="self-start ml-3">
        <Image alt="" src={ThreeArrow} />
      </div>
    </section>
  );
};

export { SectionThree };
