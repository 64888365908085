import { VFC } from 'react';
import Image from 'next/image';
import TwoPhoneProfile from '../../../public/images/home/2_PhoneProfile.png';
import { Badge } from './Badge';
import TwoArrowSm from '../../../public/images/home/2_Arrow_Sm.svg';

const SectionTwo: VFC = () => {
  return (
    <section>
      <div className="flex">
        <div>
          <Image alt="" src={TwoPhoneProfile} />
        </div>
        <div className="mt-12">
          <Badge className="mb-3">2</Badge>
          <h3 className="mb-24 font-semibold lg:text-xl">
            Your available events live on your profile.
          </h3>
          <Image alt="" src={TwoArrowSm} />
        </div>
      </div>
    </section>
  );
};

export { SectionTwo };
