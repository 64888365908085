import { VFC } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import DownloadFromApple from '../../../public/images/Download_on_the_App_Store_Badge.svg';
import DownloadFromGoogle from '../../../public/images/Google Play Badge.svg';
import { useRouter } from 'next/router';
import clsx from 'clsx';

const links = [
  { href: 'https://support.kliggit.com', label: 'Support' },
  { href: '/contact', label: 'Contact' },
];

const Header: VFC = () => {
  const router = useRouter();

  return (
    <nav className="flex items-center gap-10 sm:gap-4 mt-[3.75rem] sm:mt-[3.8rem] mb-9 p-4 sm:px-[4.375rem] lg:px-[6.25rem] xl:px-[7.5rem] 2xl:px-[8.875rem]">
      <Link href="/">
        <a className="flex flex-shrink-0 justify-center">
          <Image
            alt="kliggit logo"
            height={34}
            src="/images/gradient_logo.png"
            width={104}
          />
        </a>
      </Link>
      <div className="flex items-center ml-auto">
        <div className="hidden sm:flex sm:space-x-7 md:space-x-11 mr-[2.375rem] pl-4">
          {links.map(link => {
            const className = clsx('font-semibold pb-1', {
              'border-b border-brand ': router.asPath === link.href,
            });

            return (
              <Link key={link.href} href={link.href}>
                <a className={className}>{link.label}</a>
              </Link>
            );
          })}
        </div>
        <div className="flex justify-center items-center space-x-3">
          <div>
            <a
              className="flex justify-center"
              href="https://play.google.com/store/apps/details?id=com.kliggit.kliggitapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <Image src={DownloadFromGoogle} alt="Download from Google Play" />
            </a>
          </div>
          <div>
            <a
              className="flex justify-center"
              href="https://apps.apple.com/us/app/kliggit/id1562374705">
              <Image alt="Download from Apple" src={DownloadFromApple} />
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export { Header };
