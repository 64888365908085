import { VFC } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import DownloadFromApple from '../../../public/images/Download_on_the_App_Store_Badge.svg';
import DownloadFromGoogle from '../../../public/images/Google Play Badge.svg';

const Footer: VFC = () => {
  return (
    <footer className="flex flex-col items-center md:items-start mt-12 mb-24 p-4 sm:px-[4.375rem] lg:px-[6.25rem] xl:px-[7.5rem] 2xl:px-[8.875rem]">
      <div className="mb-6">
        <Image
          alt="kliggit logo"
          height={30.16}
          src="/images/k-logo.png"
          width={21}
        />
      </div>
      <nav className="flex flex-col items-center mb-8 text-sm md:items-start lg:flex-row lg:text-base">
        <div className="mb-2 md:mb-4 lg:mb-0">
          <a
            href="https://support.kliggit.com"
            className="mr-6 hover:underline">
            Support
          </a>
          <Link href="/contact">
            <a className="mr-6 hover:underline">Contact</a>
          </Link>
          <Link href="/privacy-policy">
            <a className="mr-6 hover:underline">Privacy Policy</a>
          </Link>
        </div>
        <div>
          <Link href="/terms">
            <a className="mr-6 hover:underline">Terms of Use</a>
          </Link>
          <Link href="/creator-terms">
            <a className="mr-6 hover:underline">Creator Terms</a>
          </Link>
          <Link href="/grievance-policy">
            <a className="hover:underline">Grievance Policy</a>
          </Link>
        </div>
      </nav>

      <div className="mb-8 space-x-4">
        <a href="https://facebook.com/kliggit.mobile">
          <ion-icon name="logo-facebook" size="large" />
        </a>
        <a href="https://twitter.com/kliggit">
          <ion-icon name="logo-twitter" size="large" />
        </a>
        <a href="https://instagram.com/kliggit">
          <ion-icon name="logo-instagram" size="large" />
        </a>
        <a href="https://www.tiktok.com/@kliggit">
          <ion-icon name="logo-tiktok" size="large" />
        </a>
      </div>

      <a
        className="flex justify-center mb-4"
        href="https://apps.apple.com/us/app/kliggit/id1562374705">
        <Image alt="Download from Apple" src={DownloadFromApple} />
      </a>

      <a
        className="flex justify-center"
        href="https://play.google.com/store/apps/details?id=com.kliggit.kliggitapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <Image src={DownloadFromGoogle} alt="Download from Google Play" />
      </a>

      <p className="mt-8 text-sm lg:text-base">&copy; 2021 kliggit</p>
    </footer>
  );
};

export { Footer };
