import Image from 'next/image';
import PhoneOne from '../../../public/images/home/5_PhoneOne.png';
import PhoneTwo from '../../../public/images/home/5_PhoneTwo.png';
import PhoneThree from '../../../public/images/home/5_PhoneThree.png';
import { VFC } from 'react';
import { Badge } from './Badge';

const SectionFive: VFC = () => {
  return (
    <section>
      <Badge className="mb-3">5</Badge>
      <h3 className="mb-14 w-1/2 font-semibold lg:text-xl">
        Public videos post on home feed and both profiles!
      </h3>
      <div className="flex">
        <Image alt="" src={PhoneOne} />
        <Image alt="" src={PhoneTwo} />
        <Image alt="" src={PhoneThree} />
      </div>
    </section>
  );
};

export { SectionFive };
