import { FC } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import Head from 'next/head';

const PageLayout: FC = ({ children }) => {
  return (
    <>
      <Head>
        <title>kliggit</title>
        <link href="/favicon.ico" rel="icon" />
      </Head>
      <Header />
      <div className="min-h-[calc(100vh-300px)] p-4 sm:px-[4.375rem] lg:px-[6.25rem] xl:px-[7.5rem] 2xl:px-[8.875rem]">
        {children}
      </div>
      <Footer />
    </>
  );
};

export { PageLayout };
