import { NextPage } from 'next';
import Image from 'next/image';
import HeroGradient from '../../public/images/home/hero_gradient.png';
import HeroPhone from '../../public/images/home/Hero_Phone.png';
import { PageLayout } from '../components/PageLayout';
import { SectionFive } from '../components/Home/SectionFive';
import { FooterCTA } from '../components/Home/FooterCTA';
import { SectionOne } from '../components/Home/SectionOne';
import { SectionTwo } from '../components/Home/SectionTwo';
import { SectionThree } from '../components/Home/SectionThree';
import { SectionFour } from '../components/Home/SectionFour';
import Head from 'next/head';
import styles from '../components/Home/styles.module.css';

const Home: NextPage = () => {
  return (
    <PageLayout>
      <Head>
        <title>kliggit | create, engage, discover</title>
        <meta
          name="description"
          content="Experience the world’s first video booking and social engagement platform."
        />
        <meta
          name="keywords"
          content="kliggit, video booking app, social engagement platform"
        />
      </Head>
      <h2 className="-mt-4 font-semibold sm:hidden lg:text-2xl">
        The world&apos;s first video booking + social media app.
      </h2>
      <div className="relative w-screen pointer-events-none xl:absolute xl:top-8 -mx-4 sm:mx-[-4.375rem] lg:mx-[-6.25rem] xl:mx-[-7.5rem] 2xl:mx-[-8.875rem]">
        <div className={styles.heroImage}>
          <Image alt="" src={HeroGradient} layout="responsive" />
        </div>
        <div className="absolute top-0 w-screen">
          <div className="flex items-center justify-center px-4 md:px-[4.4rem] lg:mt-20">
            <div className="mb-12 sm:mb-20 md:mb-4 lg:mb-16 xl:mb-28 2xl:mb-0">
              <h2 className="hidden mb-3 max-w-lg font-semibold sm:block lg:text-2xl">
                The world&apos;s first video booking + social media app.
              </h2>
              <h6 className="font-semibold sm:w-2/3 lg:text-base w-7/8">
                Engage in the world of video interactions with TalkTime and
                kliggit Reserves to be a part of the experience.
              </h6>
            </div>
            <div className="xl:mt-12">
              <Image alt="" src={HeroPhone} />
            </div>
          </div>
        </div>
      </div>
      <main className="flex flex-col items-center mt-32 xl:mt-[45rem]">
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <SectionFour />
        <SectionFive />
        <FooterCTA />
      </main>
    </PageLayout>
  );
};

export default Home;
